<template>
  <div class="fixed bg-white top-0 flex justify-center w-full shadow-md">
    <div class="container mx-auto">
      <nav class="flex items-center justify-between flex-wrap bg-teal">
        <div
          class="flex items-center logo-wrapper logo cursor-pointer md:mr-4 xl:mr-48"
        >
          <!-- <img class="logo" src="/img/logo.png" /> -->
          <nuxt-link :to="localePath('index')">
            <picture class="logo-img">
              <img src="/img/logo_2_cut.webp" alt="milsped logo" />
            </picture>
          </nuxt-link>
        </div>
        <div class="flex menu-header align-center">
          <div class="flex xl:hidden align-center relative cursor-pointer">
            <div @click="showLanguages = !showLanguages" class="self-center">
              <v-icon name="globe" class="globe block"></v-icon>
            </div>
            <div
              :class="{ hidden: !showLanguages }"
              @click="showLanguages = false"
              class="lang-list "
            >
              <nuxt-link
                v-for="locale in availableLocales"
                :key="locale.code"
                :to="switchLocalePath(locale.code)"
                @click="showLanguages = false"
                class="language"
              >
                <img
                  :src="'/img/icons/' + locale.name + '.webp'"
                  class="lang-icon"
                  loading="lazy"
                />
                <span>{{ locale.name }}</span></nuxt-link
              >
            </div>
          </div>
          <div class="block xl:hidden">
            <button
              @click="open = !open"
              class="flex items-center px-3 py-2 rounded text-teal-lighter mr-4 hover:border-white"
            >
              <v-icon name="menu" class="icon"></v-icon>
            </button>
          </div>
        </div>

        <div
          :class="open ? 'block' : 'hidden'"
          @click="open = false"
          class="menu-items w-full flex-grow items-center xl:flex xl:items-center xl:w-auto"
        >
          <div
            class="text-3xl px-4 py-2 xl:text-sm xl:flex xl:items-center  pr-4 xl:flex-grow  uppercase"
          >
            <nuxt-link
              :to="localePath('solutions')"
              class="no-underline hover:underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-6"
              >{{ $t('OUR_SOLUTIONS') }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('global-presence')"
              class="no-underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-6"
              >{{ $t('GLOBAL_PRESENCE') }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('local-information')"
              class="no-underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-6"
              >{{ $t('LOCAL_INFORMATION') }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('news')"
              class="no-underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-6"
              >{{ $t('news') }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('career')"
              class="no-underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-6"
              >{{ $t('CAREER') }}</nuxt-link
            >
            <nuxt-link
              :to="localePath('contact')"
              class="no-underline block mt-4 xl:inline-block xl:mt-0 text-teal-lighter mr-2"
              >{{ $t('CONTACT') }}</nuxt-link
            >
            <div
              class="hidden xl:inline-block xl:mt-0 align-center relative cursor-pointer desktop-only"
            >
              <div
                @click="showLanguages = !showLanguages"
                class="self-center lang-wrapper flex"
              >
                <v-icon name="globe" class="globe block"></v-icon>
                <p
                  class="no-underline text-xl block mt-4 xl:inline-block xl:mt-0 "
                >
                  {{ currentLocaleName }}
                </p>
              </div>
              <div
                :class="{ hidden: !showLanguages }"
                @click="showLanguages = false"
                class="lang-list "
              >
                <div
                  v-for="locale in availableLocales"
                  :key="locale.code"
  
                  @click="changeLanguage(locale.code)"
                  class="language"
                >
                  <img
                    :src="'/img/icons/' + locale.name + '.png'"
                    class="lang-icon"
                  />

                  <span>{{ locale.name }}</span>
                  </div
                >
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      showLanguages: false
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    currentLocaleName() {
      return this.$i18n.locales.find(i => i.code === this.$i18n.locale).name
    }
  },
 methods: {
  changeLanguage(newLocale) {
    if (this.$i18n.locale !== newLocale) {
      // this.$i18n.setLocale(newLocale);
      this.$router.push(this.switchLocalePath(newLocale));
    }
  }
  }

}
</script>

<style scoped>
.no-underline {
  line-height: 1;
}
.fixed {
  z-index: 99998;
}
nav {
  height: 80px;
}
a:hover {
  color: theme('colors.black');
  text-decoration: underline;
}
a {
  animation-delay: 1s;
  animation: slideRight 1s;
}
@screen xl {
  a {
    animation: none;
  }
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.icon {
  width: 48px;
  height: 48px;
}
.globe {
  height: 24px;
}
@screen xl {
  .globe {
    height: 17px;
  }
  a {
    @apply text-lg;
  }
}
.logo {
  max-width: 60%;
}
.lang-icon {
  height: 16px;
}
@screen sm {
  .logo {
    max-width: unset;
  }
  .logo-wrapper {
    position: relative;
  }
  /* .logo-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    z-index: -1;
    width: 1000%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    background-color: #fe0000;
  } */
}

.menu-items {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 888;
  @apply shadow-md;
  transition: 0.7s;
  animation: scale-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes scale-in-top {
  0% {
    transform: scale(0);
    transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
    opacity: 1;
  }
}

@screen xl {
  .menu-items {
    position: unset;
    top: 81px;
    left: 0;
    width: auto;
    height: unset;
    background-color: white;
    z-index: 888;
    box-shadow: none;
    animation: none;
  }
}

img {
  height: 40px;
}

.lang-list {
  position: absolute;
  top: 110%;
  z-index: 9999;
  left: 0;
}
.language {
  @apply bg-gray-200;
  @apply px-6;
  @apply py-1;
  @apply shadow-md;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  transform: translateX(-50px);
  animation: fadeIn 0.7s;
}
@screen xl {
  .language {
    transform: none;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.language span {
  min-width: 50px;
  margin-left: 15px;
}
</style>
