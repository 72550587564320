export const state = () => ({
  jobTitle: ''
})

export const mutations = {
  SET_JOB_TITLE(state, jobTitle) {
    state.jobTitle = jobTitle
  }
}

export const actions = {
  setJobTitle({ commit }, jobTitle) {
    commit('SET_JOB_TITLE', jobTitle)
  }
}

export const getters = {
  getJobTitle(state) {
    return state.jobTitle
  }
}
