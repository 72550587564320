<template>
  <div v-if="!accepted" class="banner bg-gray-100 shadow-md">
    <div
      class="container mx-auto flex flex-wrap py-8 px-4 items-center justify-around"
    >
      <div class="w-full md:w-1/2">
        <h3 class="text-3xl font-semibold">{{ $t('our_cookie_policy') }}</h3>
        <p class="text-gray-600">{{ $t('policy_info') }}</p>
      </div>
      <div class="flex flex-wrap w-full md:w-auto justify-center">
        <button
          @click="accept()"
          class="w-1/2 md:w-auto mt-8 md:mt-0 mr-1 sm:mr-3 bg-blue-600 hover:bg-blue-700 border-2 border-transparent uppercase hover:text-white text-white px-5 py-3"
        >
          {{ $t('accept') }}
        </button>
        <a
          :href="`/privacy-policy-${$i18n.locale}.pdf`"
          target="_blank"
          class="w-1/2 md:w-auto mt-8 md:mt-0 text-center bg-transparent text-black uppercase border-2 border-black  hover:bg-black hover:text-white px-5 py-3"
        >
          {{ $t('read_more') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accepted: true
    }
  },
  mounted() {
    // da se ne prikaze privacy policy banner u localhostu
    const locStorage = process.browser
      ? window.localStorage.getItem('policy_accepted')
      : false
    this.accepted = window.location.hostname === 'localhost' || locStorage
  },
  methods: {
    accept() {
      this.accepted = true
      if (process.browser) {
        window.localStorage.setItem('policy_accepted', 'true')
      }
    }
  }
}
</script>

<style scoped>
.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  min-height: 100px;
  z-index: 9999999;
}
</style>
